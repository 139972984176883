import React, { useState, useEffect } from "react"
import Select from 'react-select'
import { StaticImage } from "gatsby-plugin-image"

import { Badge, StaticCheckImage, StyledCheckbox } from "./ReusableSnippets"
import { getClientId, getURLWithClientID, operatorFontColors } from "../../../_helpers"

const orderOptions = [
    {value: "priceLowestFirst", label: "Edullisin kk-maksu ensin"},
    {value: "priceHighestFirst", label: "Korkein kk-maksu ensin"},
    {value: "internetSpeedHighestFirst", label: "Nopein netti ensin"},
]

const showDetails = event => {
    const parent = event.target.parentNode
    const detailsElement = parent.querySelector(".comparisonTableContainer__resultCard__additionalInformationContainer_additionalInformation")
    detailsElement.classList.toggle("visible")
}

const resultsIfNotShowingAll = 4

const PuhelinliittymaVertailu = ({
    operaattori,
    puhelinliittymaData,
    initialHideNon4GProducts = true,
    initialHideNonUnlimitedDataProducts = false,
    initialHideNonUnlimitedTalkProducts = false,
    initialHideNonUnlimitedSMSProducts = false,
    initialHideNonOfferPrices = false,
    oletusjarjestys = "cheapest"
}) => {
    let defaultMode = "priceLowestFirst"
    if (oletusjarjestys === "mostexpensive") defaultMode = "priceHighestFirst"
    else if (oletusjarjestys === "fastest") defaultMode = "internetSpeedHighestFirst"

    const [mode, setMode] = useState(defaultMode)
    const [hideNon4GProducts, setHideNon4GProducts] = useState(initialHideNon4GProducts)
    const [hideNonUnlimitedDataProducts, setHideNonUnlimitedDataProducts] = useState(initialHideNonUnlimitedDataProducts)
    const [hideNonUnlimitedTalkProducts, setHideNonUnlimitedTalkProducts] = useState(initialHideNonUnlimitedTalkProducts)
    const [hideNonUnlimitedSMSProducts, setHideNonUnlimitedSMSProducts] = useState(initialHideNonUnlimitedSMSProducts)
    const [hideNonOfferPrices, setHideNonOfferPrices] = useState(initialHideNonOfferPrices)
    const [clientId, setClientId] = useState("")
    // To limit DOM size, don't initially show all results in full comparison tables
    const [resultLimit, setResultLimit] = useState(operaattori.includes("all") ? resultsIfNotShowingAll : 999)

    useEffect(() => {
        // isMounted is utilized to 
        let isMounted = true

        setTimeout(() => {
            if (isMounted) setClientId(getClientId())
        }, 2500)

        return () => isMounted = false
    }, [])

    // Clone the original data array
    let orderedAndFilteredData = [...puhelinliittymaData]

    // Filters
    orderedAndFilteredData = orderedAndFilteredData.filter(item => {
        // Only show the values that match the 'operaattori' Advanced Custom Field value
        // the variable "operaattori" is an Array
        // "all" means "show all operators" (no way?!)
        if (!operaattori.includes("all") && !operaattori.includes(item?.["Operator"].toLowerCase()))
            return false
        
        // Hide prepaid items from this page
        if (item?.['Prepaid'] === "1")
            return false

        if (hideNon4GProducts === true && item?.["Is4G"] !== "1")
            return false

        if (hideNonUnlimitedDataProducts === true && item?.["UnlimitedData"] !== "1")
            return false

        if (hideNonUnlimitedTalkProducts === true && item?.["UnlimitedTalk"] !== "1")
            return false

        if (hideNonUnlimitedSMSProducts === true && item?.["UnlimitedSMS"] !== "1")
            return false

        if (hideNonOfferPrices === true && !item?.["OfferPrice"])
            return false

        return true
    })

    orderedAndFilteredData.sort((a, b) => {
        let aMonthlyPrice = parseFloat(a['Price'])
        let bMonthlyPrice = parseFloat(b['Price'])

        if (a['OfferPrice'])
            aMonthlyPrice = parseFloat(a['OfferPrice'])

        if (b['OfferPrice'])
            bMonthlyPrice = parseFloat(b['OfferPrice'])

        if (mode === "priceLowestFirst") {
            if (aMonthlyPrice < bMonthlyPrice)
                return -1
            else if (aMonthlyPrice > bMonthlyPrice)
                return 1
            else
                return 0
        } else if (mode === "priceHighestFirst") {
            if (aMonthlyPrice > bMonthlyPrice)
                return -1
            else if (aMonthlyPrice < bMonthlyPrice)
                return 1
            else
                return 0
        } else { // internetSpeedHighestFirst
            const aInternetSpeed = parseInt(a['DataSpeed'])
            const bInternetSpeed = parseInt(b['DataSpeed'])

            if (aInternetSpeed > bInternetSpeed)
                return -1
            else if (aInternetSpeed < bInternetSpeed)
                return 1
            else
                return 0
        }
    })

    return (
        <div className="comparisonTableContainer puhelinliittymavertailuContainer">
            
            <span className="comparisonTableContainer__orderByTitle">
                Järjestys
            </span>

            <Select options={orderOptions}
                    defaultValue={orderOptions.filter(item => item.value === mode)}
                    onChange={data => setMode(data.value)}
                    placeholder="Valitse.."
                    className="reactSelect"
            />

            <StyledCheckbox onChangeHandler={e => setHideNon4GProducts(e.target.checked)}
                            id="only4G"
                            label="4G tai nopeampi"
                            checked={hideNon4GProducts}
            />

            <StyledCheckbox onChangeHandler={e => setHideNonUnlimitedDataProducts(e.target.checked)}
                            id="onlyUnlimitedData"
                            label="Rajaton netti"
                            checked={hideNonUnlimitedDataProducts}
            />

            <StyledCheckbox onChangeHandler={e => setHideNonUnlimitedTalkProducts(e.target.checked)}
                            id="onlyUnlimitedTalk"
                            label="Rajaton puhe"
                            checked={hideNonUnlimitedTalkProducts}
            />

            <StyledCheckbox onChangeHandler={e => setHideNonUnlimitedSMSProducts(e.target.checked)}
                            id="onlyUnlimitedSMS"
                            label="Rajaton SMS"
                            checked={hideNonUnlimitedSMSProducts}
            />

            <StyledCheckbox onChangeHandler={e => setHideNonOfferPrices(e.target.checked)}
                            id="onlyOfferPrices"
                            label="Tarjoushintaiset"
                            checked={hideNonOfferPrices}
            />

            <div className="comparisonTableContainer__resultCards">

                { resultLimit === resultsIfNotShowingAll && (
                    <div className="showAllOverlay">
                        <button className="showAllResultsButton" type="button" onClick={() => setResultLimit(999)}>
                            Näytä kaikki tulokset ({ orderedAndFilteredData.length } kpl)
                        </button>
                    </div>
                )}

                { orderedAndFilteredData.map((item, i) => {
                    if (i >= resultLimit)
                        return <></>

                    return (
                        <div key={`comparisonTableItem-${i}`} className="comparisonTableContainer__resultCard">
                            <div    className="comparisonTableContainer__resultCard__title"
                                    style={{ color: operatorFontColors[item['Operator']]}}
                            >
                                <span className="colouredBall">{ i+1 }.</span> { `${item['Operator']} ${item['Product']}` }
                            </div>
                            <div className="comparisonTableContainer__resultCard__splitContainer">
                                <div className="comparisonTableContainer__resultCard__logoContainer">
                                    { item['Operator'] === 'Saunalahti' &&
                                        <StaticImage src={`../../../img/operatorLogos/Saunalahti.png`} alt={`Elisa Saunalahti logo`} />
                                    }
                                    { item['Operator'] === 'Elisa' &&
                                        <StaticImage src={`../../../img/operatorLogos/Elisa.png`} alt={`Elisa logo`} />
                                    }
                                    { item['Operator'] === 'Moi' &&
                                        <StaticImage src={`../../../img/operatorLogos/Moi.png`} alt={`Moi logo`} />
                                    }
                                    { item['Operator'] === 'Telia' &&
                                        <StaticImage src={`../../../img/operatorLogos/Telia.png`} alt={`Telia logo`} />
                                    }
                                    { item['Operator'] === 'DNA' &&
                                        <StaticImage src={`../../../img/operatorLogos/DNA.png`} alt={`DNA logo`} />
                                    }
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyPointsContainer">
                                    { item['KeyPoints'].split("---").map((keyPoint, _i) => (
                                        <div key={`comparisonTableItem-${i}-keyPoint-${_i}`} className="comparisonTableContainer__resultCard__keyPointsContainer__keyPoint">
                                            <StaticCheckImage /> { keyPoint }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="comparisonTableContainer__resultCard__CTAContainer">
                                <a  href={getURLWithClientID(item['affurlpuhelinliittymat'], clientId)}
                                    data-operatorname={item['Operator']}
                                    data-productname={item['Product']}
                                    data-fullname={`${item['Operator']} ${item['Product']}`}
                                    className="comparisonTableContainer__resultCard__CTA"
                                    target="_blank"
                                    rel="noreferrer nofollow sponsored"
                                >
                                    TUTUSTU JA TILAA
                                </a>
                                <span className="comparisonTableContainer__resultCard__description">
                                    Linkki avautuu uuteen välilehteen.
                                </span>
                            </div>
                            <div className="comparisonTableContainer__resultCard__keyDataTableContainer">
                                <div style={{ color: operatorFontColors[item['Operator']]}}
                                    className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow"
                                >
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title"
                                        style={{ color: operatorFontColors[item['Operator']]}}
                                    >
                                        Kuukausimaksu
                                    </div>
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value">
                                        { item['OfferPrice'] && <>
                                            {item['OfferPrice'].replace(".", ",")}&nbsp;€/kk
                                            <s style={{ color: "rgba(0,0,0,.3)", marginLeft: "8px", fontSize: "15.5px" }}>{item['Price'].replace(".", ",")}&nbsp;€</s>
                                        </>}
                                        { !item['OfferPrice'] && <>
                                            {item['Price'].replace(".", ",")}&nbsp;€/kk
                                        </>}
                                    </div>
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow">
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title">
                                        Avausmaksu
                                    </div>
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value">
                                        {item['UpfrontCost'].replace(".", ",")}&nbsp;€
                                    </div>
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow">
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title">
                                        Netin max. nopeus
                                    </div>
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value">
                                        { item?.['NoData'] !== "1" &&
                                            <>{item['DataSpeed']}&nbsp;{item['dataspeedlabel']}</>
                                        }
                                        { item?.['IsSlow'] === "1" && <Badge type="alert">HIDAS</Badge> }
                                        { item?.['NoData'] === "1" && <Badge>EI&nbsp;NETTIÄ</Badge> }
                                    </div>
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow">
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title">
                                        Netin käyttö
                                    </div>
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value">
                                        {item['DataLimitNew']}
                                    </div>
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow">
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title">
                                        Puhelut
                                    </div>
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value">
                                        {item['PuhelutLabel']}
                                    </div>
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow">
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title">
                                        Tekstiviestit
                                    </div>
                                    <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value">
                                        {item['ViestitLabel']}
                                    </div>
                                </div>
                                { item?.['SBonus'] && 
                                    <div style={{ textAlign: "center" }}>
                                        <StaticImage title="Tämä liittymä kerryttää S-Bonusta" height={54} src={`../../../img/sbonus.png`} alt={`S-Bonus logo`} />
                                    </div>
                                }
                            </div>
                            <div className="comparisonTableContainer__resultCard__additionalInformationContainer">
                                <button onClick={e => showDetails(e)} type="button" className="comparisonTableContainer__resultCard__additionalInformationContainer_toggleButton">
                                    Lisätiedot
                                </button>
                                <div className="comparisonTableContainer__resultCard__additionalInformationContainer_additionalInformation">
                                    <ul style={{ marginBottom: 0 }}>
                                        { item?.['Offer duration'] && <li>Tarjouksen kesto: { item['Offer duration'] } kk</li> }
                                        { item?.['Offer duration'] && <li>Kuukausimaksu tarjouksen jälkeen: { item['Price'] } €/kk</li> }
                                        <li>EU-dataa: { item?.['RoamingGbPerMonthNew'] || "-" }</li>
                                        { item?.['SBonus'] && <li>Kerryttää S-Bonusta: Kyllä</li> }
                                        <li>Verkko: { item?.['network'] || "Ei tiedossa" }</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default PuhelinliittymaVertailu
