import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Badge = ({ children,  type = "danger" }) => (
    <span className={`badge ${type}`}>{ children }</span>
)

/**
 * https://sharingbuttons.io/
 */
export const SharingButtons = ({ shareURL }) => (
    <div className="sharingButtons">
        <a className="resp-sharing-button__link" href={`https://facebook.com/sharer/sharer.php?u=${shareURL}`} target="_blank" rel="noopener noreferrer" aria-label="Jaa Facebookissa">
        <div className="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.77 7.5H14.5V5.6c0-.9.6-1.1 1-1.1h3V.54L14.17.53C10.24.54 9.5 3.48 9.5 5.37V7.5h-3v4h3v12h5v-12h3.85l.42-4z"/></svg></div><span className="hideMobile">&nbsp;Jaa Facebookissa</span></div>
        </a>
        <a className="resp-sharing-button__link" href={`whatsapp://send?text=${shareURL}`} target="_blank" rel="noopener noreferrer" aria-label="Jaa WhatsAppissa">
        <div className="resp-sharing-button resp-sharing-button--whatsapp resp-sharing-button--medium"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
            <WhatsAppIcon /></div><span className="hideMobile">&nbsp;Jaa WhatsAppissa</span></div>
        </a>
    </div>
)


export const WhatsAppIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" fill="#fff" height="24" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
)

export const StaticCheckImage = () => (
    <StaticImage style={{display: "inline-block", position: "relative", top: "3px", marginRight: "4px"}} layout="fixed" height={16} width={16} src={`../../../img/check.svg`} alt={`Check emoji`} />
)

export const StyledCheckbox = ({ onChangeHandler, id, label, checked = false }) => (
    <div className="styledCheckboxContainer">
        <div className="styledCheckbox pretty p-switch p-fill">
            <input checked={!!checked} onChange={onChangeHandler} type="checkbox" id={id} />
            <div className="state p-danger">
                <label htmlFor={id}>{label}</label>
            </div>
        </div>
    </div>
)
