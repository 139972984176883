import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const DefaultTemplate = ({ id, title, content }) => {
    let firstSectionContent = ""
    let restOfTheContent = ""
    if (!content) content = ""

    for (let el of ["<h2", "<h3", "<h4", "<h5", "<h6"]) {
        let hit = content.indexOf(el)
        if (hit !== -1) {
            firstSectionContent = content.substring(0, hit)
            restOfTheContent = content.substring(hit)
            break
        }
    }

    if (!firstSectionContent) {
        firstSectionContent = content
    }

    return (
        <div className={`post-${id}`}>
            <div className="section purpleBackground isFirstSection">
                <div className="purpleBackgroundContentWrapper">
                    <div className="container">
                        <div className="innerPadding">
                            <div dangerouslySetInnerHTML={{ __html: firstSectionContent }} />
                        </div>
                    </div>
                </div>
                <div className="purpleBackgroundImageWrapper">
                    <StaticImage src={`https://wp.puhelinliittymat.fi/wp-content/uploads/2021/06/oranssi-mohkale.png`} alt={`Kuvituskuva`} />
                </div>
            </div>
            { restOfTheContent !== "" && (
                <div className="section">
                    <div className="container">
                        <div className="innerPadding" dangerouslySetInnerHTML={{ __html: restOfTheContent }} />
                    </div>
                </div>
            ) }
        </div>
    )
}

export default DefaultTemplate
