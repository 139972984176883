import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import PuhelinliittymaVertailu from "./components/PuhelinliittymaVertailu"
import MobiililaajakaistaVertailu from "./components/MobiililaajakaistaVertailu"

import {
    COMPARISON_TABLES_MOBIILILAAJAKAISTAVERTAILU,
    COMPARISON_TABLES_PUHELINLIITTYMAVERTAILU
} from "../../_constants"

export const Vertailu = props => {
    const {
        id,
        content,
        vertailu,
        puhelinliittymaData,
        mobiililaajakaistaData,
    } = props

    const {
        operaattori,
        ingressi,
        vertailutaulukko,
        vertailutaulukonSisalto,
        nopeus4gTaiNopeampiOletusasetus,
        rajatonNettiOletusasetus,
        rajatonPuheOletusasetus,
        rajatonSmsOletusasetus,
        vainTarjouksetOletusasetus,
        oletusjarjestys
    } = vertailu

    return (
        <div className={`post-${id}`}>
            <div className="section purpleBackground isFirstSection">
                <div className="purpleBackgroundContentWrapper">
                    <div className="container">
                        <div className="innerPadding">
                            <div dangerouslySetInnerHTML={{__html: ingressi}} />
                        </div>
                    </div>
                </div>
                <div className="purpleBackgroundImageWrapper">
                    <StaticImage src={`https://wp.puhelinliittymat.fi/wp-content/uploads/2021/06/oranssi-mohkale.png`} alt={`Kuvituskuva`} />
                </div>
            </div>
            <div className="container">
                <div className="comparisonTableSection" id="vertailu">
                    <div dangerouslySetInnerHTML={{ __html: vertailutaulukonSisalto }} />
                    { vertailutaulukko === COMPARISON_TABLES_PUHELINLIITTYMAVERTAILU && 
                        <PuhelinliittymaVertailu    initialHideNonOfferPrices={vainTarjouksetOletusasetus}
                                                    operaattori={operaattori}
                                                    puhelinliittymaData={puhelinliittymaData}
                                                    initialHideNon4GProducts={nopeus4gTaiNopeampiOletusasetus}
                                                    initialHideNonUnlimitedDataProducts={rajatonNettiOletusasetus}
                                                    initialHideNonUnlimitedTalkProducts={rajatonPuheOletusasetus}
                                                    initialHideNonUnlimitedSMSProducts={rajatonSmsOletusasetus}
                                                    oletusjarjestys={oletusjarjestys}
                        /> 
                    }
                    { vertailutaulukko === COMPARISON_TABLES_MOBIILILAAJAKAISTAVERTAILU && 
                        <MobiililaajakaistaVertailu operaattori={operaattori}
                                                    mobiililaajakaistaData={mobiililaajakaistaData}
                                                    initialHideNon4GProducts={nopeus4gTaiNopeampiOletusasetus}
                                                    initialHideNonUnlimitedDataProducts={rajatonNettiOletusasetus}
                                                    initialHideNonUnlimitedTalkProducts={rajatonPuheOletusasetus}
                                                    initialHideNonUnlimitedSMSProducts={rajatonSmsOletusasetus}
                                                    oletusjarjestys={oletusjarjestys}
                        /> 
                    }
                </div>
                
                <div className="contentSection">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
            </div>
        </div>
    )
}

export default Vertailu
