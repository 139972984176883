import React, { useState, useEffect } from "react"
import Select from 'react-select'
import { StaticImage } from "gatsby-plugin-image"

import { Badge, StaticCheckImage } from "./ReusableSnippets"
import { getClientId, getURLWithClientID, operatorFontColors } from "../../../_helpers"

const orderOptions = [
    {value: "priceLowestFirst", label: "Edullisin kk-maksu ensin"},
    {value: "priceHighestFirst", label: "Korkein kk-maksu ensin"},
    {value: "internetSpeedHighestFirst", label: "Nopein netti ensin"}
]

const MobiililaajakaistaVertailu = ({ operaattori, mobiililaajakaistaData }) => {
    const [mode, setMode] = useState("internetSpeedHighestFirst")
    const [clientId, setClientId] = useState("")

    useEffect(() => {
        // isMounted is utilized to 
        let isMounted = true

        setTimeout(() => {
            if (isMounted) setClientId(getClientId())
        }, 2500)

        return () => isMounted = false
    }, [])

    // Clone the original data array
    let orderedAndFilteredData = [...mobiililaajakaistaData]

    orderedAndFilteredData.sort((a, b) => {
        let aMonthlyPrice = parseFloat(a['Hinta'])
        let bMonthlyPrice = parseFloat(b['Hinta'])

        if (mode === "priceLowestFirst") {
            if (aMonthlyPrice < bMonthlyPrice)
                return -1
            else if (aMonthlyPrice > bMonthlyPrice)
                return 1
            else
                return 0
        } else if (mode === "priceHighestFirst") {
            if (aMonthlyPrice > bMonthlyPrice)
                return -1
            else if (aMonthlyPrice < bMonthlyPrice)
                return 1
            else
                return 0
        } else { // internetSpeedHighestFirst
            const aInternetSpeed = parseInt(a['Nopeus'])
            const bInternetSpeed = parseInt(b['Nopeus'])

            if (aInternetSpeed > bInternetSpeed)
                return -1
            else if (aInternetSpeed < bInternetSpeed)
                return 1
            else
                return 0
        }
    })
    
    // the variable "operaattori" is an Array
    if (!operaattori.includes("all")) {
        orderedAndFilteredData = orderedAndFilteredData.filter(item => (
            operaattori.includes(item?.["Operator"].toLowerCase()))
        )
    }

    return (
        <div className="comparisonTableContainer puhelinliittymavertailuContainer">
            
            <span className="comparisonTableContainer__orderByTitle">
                Järjestys
            </span>

            <Select options={orderOptions}
                    defaultValue={orderOptions.filter(item => item.value === mode)}
                    onChange={data => setMode(data.value)}
                    placeholder="Valitse.."
                    className="reactSelect"
            />

            <div className="comparisonTableContainer__resultCards">
                { orderedAndFilteredData.map((item, i) => (
                    <div key={`comparisonTableItem-${i}`} className="comparisonTableContainer__resultCard">
                        <div    className="comparisonTableContainer__resultCard__title"
                                style={{ color: operatorFontColors[item['Operator']]}}
                        >
                            <span className="colouredBall">{ i+1 }.</span> { `${item['Operator']} ${item['Liittyma']}` }
                        </div>
                        <div className="comparisonTableContainer__resultCard__splitContainer">
                            <div className="comparisonTableContainer__resultCard__logoContainer">
                                { item['Operator'] === 'Saunalahti' &&
                                    <StaticImage src={`../../../img/operatorLogos/Saunalahti.png`} alt={`Elisa Saunalahti logo`} />
                                }
                                { item['Operator'] === 'Elisa' &&
                                    <StaticImage src={`../../../img/operatorLogos/Elisa.png`} alt={`Elisa logo`} />
                                }
                                { item['Operator'] === 'Moi' &&
                                    <StaticImage src={`../../../img/operatorLogos/Moi.png`} alt={`Moi logo`} />
                                }
                                { item['Operator'] === 'Telia' &&
                                    <StaticImage src={`../../../img/operatorLogos/Telia.png`} alt={`Telia logo`} />
                                }
                                { item['Operator'] === 'DNA' &&
                                    <StaticImage src={`../../../img/operatorLogos/DNA.png`} alt={`DNA logo`} />
                                }
                            </div>
                            <div className="comparisonTableContainer__resultCard__keyPointsContainer">
                                { item['KeyPoints'].split("---").map((keyPoint, _i) => (
                                    <div key={`comparisonTableItem-${i}-keyPoint-${_i}`} className="comparisonTableContainer__resultCard__keyPointsContainer__keyPoint">
                                        <StaticCheckImage /> { keyPoint }
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="comparisonTableContainer__resultCard__CTAContainer">
                            <a  href={getURLWithClientID(item['affurlpuhelinliittymat'], clientId)}
                                data-operatorname={item['Operator']}
                                data-productname={item['Liittyma']}
                                data-fullname={`${item['Operator']} ${item['Liittyma']}`}
                                className="comparisonTableContainer__resultCard__CTA"
                                target="_blank"
                                rel="noreferrer nofollow sponsored"
                            >
                                TUTUSTU JA TILAA
                            </a>
                            <span className="comparisonTableContainer__resultCard__description">
                                Linkki avautuu uuteen välilehteen.
                            </span>
                        </div>
                        <div className="comparisonTableContainer__resultCard__keyDataTableContainer">
                            <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow">
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title"
                                     style={{ color: operatorFontColors[item['Operator']]}}
                                >
                                    Kuukausimaksu
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value"
                                     style={{ color: operatorFontColors[item['Operator']]}}
                                >
                                    {item['Hinta'].replace(".", ",")}&nbsp;€
                                </div>
                            </div>
                            <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow">
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title">
                                    Avausmaksu
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value">
                                    {item['Avaus'].replace(".", ",")}&nbsp;€
                                </div>
                            </div>
                            <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow">
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_title">
                                    Netin max. nopeus
                                </div>
                                <div className="comparisonTableContainer__resultCard__keyDataTableContainer_keyDataRow_value">
                                    { item?.['Nodata'] !== "1" &&
                                        <>{item['Nopeus']}&nbsp;{item['NopeusLabel']}</>
                                    }
                                    { item?.['IsSlow'] === "1" && <Badge type="alert">HIDAS</Badge> }
                                </div>
                            </div>
                        </div>
                        <div className="comparisonTableContainer__resultCard__additionalInformationContainer">
                            { item?.['Huom'] }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MobiililaajakaistaVertailu
