import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { BLANK_FULL_WIDTH, TEMPLATE_VERTAILU } from "../_constants"

import Layout from "../Layout"
import DefaultTemplate from "./pageTemplates/DefaultTemplate"
import Vertailu from "./pageTemplates/Vertailu"
import BlankFullWidthTemplate from "./pageTemplates/BlankFullWidthTemplate"

const Page = query => {
    const { uri, title, content, id, template, featuredImage, seoData, modifiedGmt, wpParent } = query.data.wpPage
    const defaultProps = { uri, id, title, content, modifiedGmt }
    const { puhelinliittymaData, mobiililaajakaistaData } = query.pageContext
    
    /**
     * We need to build a Parent Pages array to display a breadcrumb. This is a fairly disgusting way to achieve that
     * but it works so what the heck. It currently works up to 3 levels.
     */
    let parentPages = []
    if (wpParent !== null) {
        if (wpParent?.node?.title) {
            parentPages.unshift({
                title: wpParent.node.title,
                url: wpParent.node.uri
            })
        }

        if (wpParent?.node && wpParent?.node?.wpParent !== null) {
            if (wpParent?.node?.wpParent?.node?.title) {
                parentPages.unshift({
                    title: wpParent?.node?.wpParent.node.title,
                    url: wpParent?.node?.wpParent.node.uri
                })
            }

            if (wpParent?.node?.wpParent?.node && wpParent?.node?.wpParent?.node?.wpParent !== null) {
                if (wpParent?.node?.wpParent?.node?.wpParent?.node?.title) {
                    parentPages.unshift({
                        title: wpParent?.node?.wpParent?.node?.wpParent.node.title,
                        url: wpParent?.node?.wpParent?.node?.wpParent.node.uri
                    })
                }
            }
        }
    }

    /**
     * if Default template
     */

    let pageContents = (
        <DefaultTemplate {...defaultProps} />
    )

    /**
     * if Vertailu template
     */

    if (template.templateName === TEMPLATE_VERTAILU) {
        pageContents = (
            <Vertailu   {...defaultProps}
                        vertailu={template.vertailu}
                        puhelinliittymaData={puhelinliittymaData}
                        mobiililaajakaistaData={mobiililaajakaistaData}
                        featuredImage={featuredImage}
            />
        )
    }

    else if (template.templateName === BLANK_FULL_WIDTH) {
        pageContents = (
            <BlankFullWidthTemplate {...defaultProps} />
        )
    }

    /**
     * Render the template
     */

    const metaTitle = seoData?.metaTitle || title || "Puhelinliittymat.fi"
    const metaDescription = seoData?.metaDescription || "Puhelinliittymat.fi auttaa sinua säästämään satoja euroja vuodessa. Säästä selvää rahaa vertaamalla ja kilpailuttamalla nykyinen tai uusi puhelinliittymäsi."

    return (
        <Layout currentURL={query.path}
                currentTitle={title}
                modifiedGmt={modifiedGmt}
                parentPages={parentPages}
                uri={uri}
        >
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="title" content={metaTitle} />
                <meta name="description" content={metaDescription} />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={uri} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:description" content={metaDescription} />
                <meta property="twitter:url" content={uri} />
                <meta property="twitter:title" content={metaTitle} />
                <meta property="twitter:description" content={metaDescription} />
                <link rel="canonical" href={`https://puhelinliittymat.fi${uri}`} />
                { !!featuredImage?.node?.mediaItemUrl && <meta property="og:image" content={featuredImage.node.mediaItemUrl} /> }
                { !!featuredImage?.node?.mediaItemUrl && <meta property="twitter:card" content="summary_large_image" /> }
                { !!featuredImage?.node?.mediaItemUrl && <meta property="twitter:image" content={featuredImage.node.mediaItemUrl} /> }
            </Helmet>
            <main id="main">
                { pageContents }
            </main>
        </Layout>
    )
}

export const query = graphql`
    query($id: String) {
        wpPage(id: { eq: $id }) {
            id
            uri
            title
            content
            modifiedGmt
            seoData {
                metaTitle
                metaDescription
            }
            template {
                templateName
                ... on WpTemplate_Vertailu {
                    vertailu {
                        operaattori
                        ingressi
                        vertailutaulukonSisalto
                        vertailutaulukko
                        nopeus4gTaiNopeampiOletusasetus
                        rajatonNettiOletusasetus
                        rajatonPuheOletusasetus
                        rajatonSmsOletusasetus
                        vainTarjouksetOletusasetus
                        oletusjarjestys
                    }
                }

            }
            featuredImage {
                node {
                    mediaItemUrl
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            wpParent {
                node {
                    id
                    uri
                    ... on WpPage {
                        id
                        title
                        wpParent {
                            node {
                                id
                                uri
                                ... on WpPage {
                                    id
                                    title
                                    wpParent {
                                        node {
                                            id
                                            uri
                                            ... on WpPage {
                                                id
                                                title
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default Page
