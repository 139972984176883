export const SITE_URL = "https://puhelinliittymat.fi"

/**
 * WP Page Template names
 */

export const TEMPLATE_VERTAILU = "Vertailu"
export const BLANK_FULL_WIDTH = "Blank Template, Full Width"

/**
 * Comparison table identifiers
 */

export const COMPARISON_TABLES_PUHELINLIITTYMAVERTAILU = "puhelinliittymat"
export const COMPARISON_TABLES_MOBIILILAAJAKAISTAVERTAILU = "mobiililaajakaistat"
export const COMPARISON_TABLES_TARJOUKSET_PUHELINLIITTYMAVERTAILU = "puhelinliittymat_tarjoukset"
